import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <p>Under construction for now. :)</p>
    <p>Happy browsing.</p>
  </Layout>
)

export default IndexPage
